import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
    report:[],
    vendorExportList:{},
  }
  
export const reportSlices = createSlice({
    name: 'reportSlice',
    initialState,
    reducers:{
            
        allVendorExcelRequest : (state, action) => {            
            state.vendorExportList = action.payload;
        },
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
          }, 
    }
})

export const {allVendorExcelRequest,apiFailed} = reportSlices.actions
export default reportSlices.reducer

export const fetchReport = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/report/get-report`, values)
        
         dispatch(allVendorExcelRequest(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
            
  
}


