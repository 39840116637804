import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  brand: {},
  viewbrand:{},
  brands : [],
  brandEdit:{},
  brandEditMsg : "",
  branddeleteSuccess : false,
}

export const brandSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allBrands : (state, action) => {
      state.brands = action.payload;
    },
    createBrand : (state, action) => {
      state.successMsg = action.payload;
    },
    brandDetail : (state, action) => {
      state.viewbrand = action.payload;
    },
    brandEdit : (state, action) => {
      state.brandEdit = action.payload;
    },
    brandEditMsg : (state, action) => {
      state.brandEditMsg = action.payload;
    },
    statusBrand : (state, action) => {
      state.brand = action.payload;
    },
    brandDelete : (state) => {
      state.branddeleteSuccess = true;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.branddeleteSuccess = false;
      state.errorApiData = "";
      state.viewbrand = "";
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, brandEditMsg,brandEdit,brandDetail,statusBrand,createBrand,incrementByAmount, brandDelete, apiFailed,allBrands,resetState } = brandSlice.actions

export default brandSlice.reducer


export const getBrands = (page,search) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/brand/getBrands?search=${search}&page=${page}`);
      dispatch(allBrands(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const addBrand = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/brand/addBrand`, values)
      dispatch(createBrand(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const updateBrand = (brand_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/brand/updateBrand`,brand_id)
      dispatch(brandEditMsg("Brand updated successfully"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailBrand = (brand_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/brand/viewBrand/`,{brandId:brand_id});
      dispatch(brandDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteBrand = (brand_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/brand/deleteBrand`,{brandId : brand_id})
      dispatch(brandDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const changeBrandStatus = (statusData) => async dispatch => {
  try{

      const res = await Api('post',`${REACT_APP_API_URL}/brand/status`,statusData);
      dispatch(statusBrand());
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(brandEditMsg(""));
}