import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  coupon: {},
  viewcoupon:{},
  coupons : [],
  vendors : [],
  users : [],
  couponEdit:{},
  couponEditMsg : "",
  globalPage : 1
}

export const couponCodeSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allCoupons : (state, action) => {
      state.coupons = action.payload;
    },
    createCoupon : (state, action) => {
      state.successMsg = action.payload;
    },
    couponDetail : (state, action) => {
      state.viewcoupon = action.payload;
    },
    couponEdit : (state, action) => {
      state.couponEdit = action.payload;
    },
    couponEditMsg : (state, action) => {
      state.successMsg = action.payload;
    },
    couponUpdateStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    allVendors : (state, action) => {
      state.vendors = action.payload;
    },
    allUsers : (state, action) => {
      state.users = action.payload;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.errorApiData = "";
      state.viewcoupon = "";
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { couponEditMsg,couponEdit,couponDetail,createCoupon, apiFailed,allCoupons,resetState,couponUpdateStatus, setGlobalPage, allVendors, allUsers } = couponCodeSlice.actions

export default couponCodeSlice.reducer


export const getVendors = () => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/product/getVendors`);
      dispatch(allVendors(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const getUsers = () => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/coupon/getUsers`);
      dispatch(allUsers(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const getCoupons = (page,search) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/coupon/getCoupons?search=${search}&page=${page}`);
      dispatch(allCoupons(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const addCoupon = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/coupon/addCoupon`, values)
      dispatch(createCoupon(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const updateCoupon = (coupon_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/coupon/updateCoupon`,coupon_id)
      console.log("res",res.data.message);
      dispatch(couponEditMsg(res.data.message))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailCoupon = (coupon_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/coupon/viewCoupon/`,{coupon_id:coupon_id});
      dispatch(couponDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const statusChangeCoupon = (value) => async dispatch => {
  try{
      const res = await Api('post', `${REACT_APP_API_URL}/coupon/updateCouponStatus`, value)
      dispatch(couponUpdateStatus(res.data.message))
  }catch(e){
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(couponEditMsg(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}