import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  company: {},
  viewcompany:{},
  companies : [],
  companyEdit:{},
  companyEditMsg : "",
  deleteSuccess : false,
}

export const companySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
      allCompanies : (state, action) => {
      state.companies = action.payload;
    },
    //   statusCompany : (state, action) => {
    //   state.company = action.payload;
    // },
    createCompany : (state, action) => {
      state.successMsg = action.payload;
     },
      companyDetail : (state, action) => {
      state.viewcompany = action.payload;
    },
      companyEdit : (state, action) => {
      state.companyEdit = action.payload;
    },
    companyEditMsg : (state, action) => {
      state.companyEditMsg = action.payload;
    },
    companyDelete : (state) => {
      state.deleteSuccess = true;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.deleteSuccess = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, companyEditMsg,companyEdit,companyDetail,createCompany,incrementByAmount, companyDelete, apiFailed,allCompanies,resetState } = companySlice.actions

export default companySlice.reducer


export const getCompanies = (page,search) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/company/getListCompany?page=${page}&search=${search}`);
      dispatch(allCompanies(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }

  
}


export const addCompany = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/company/addCompany`, values)
      dispatch(createCompany(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

// export const editCompany = (company_id) => async dispatch => {
//   try {
//       const res = await Api('post', `${REACT_APP_API_URL}/company/updateCustomer/${company_id}`)
//       dispatch(companyEdit(res.data))
//   } catch (e) {
//       if (e.response) {
//           dispatch(apiFailed(e.response.data))
//       } else {
//           dispatch(apiFailed(e.message))
//       }
//   }
// }

export const updateCompany = (company_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/company/editCompany`,company_id)
      dispatch(companyEditMsg("Company updated successfully"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailCompany = (company_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/company/viewCompany/`,{company_id:company_id});
      dispatch(companyDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteCompany = (company_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/company/deleteCompany`,{companyId : company_id})
      dispatch(companyDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}


// export const statusChanageCompany = (statusData) => async dispatch => {
//   try{

//       const res = await Api('post',`${REACT_APP_API_URL}/company/status`,statusData);
//       dispatch(statusCompany());
//   }catch(e){
//       if(e.response){
//           dispatch(apiFailed(e.response.data.message));
//       }else{
//           dispatch(apiFailed(e.message));
//       }
//   }
// }

export const clearData = () => dispatch => {
  dispatch(companyEditMsg(""));
}