import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  offer: {},
  viewoffer:{},
  offers : [],
  offerEdit:{},
  offerEditMsg : "",
  offerdeleteSuccess : false,
}

export const offerSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allOffers : (state, action) => {
      state.offers = action.payload;
    },
    createOffer : (state, action) => {
      state.successMsg = action.payload;
    },
    offerDetail : (state, action) => {
      state.viewoffer = action.payload;
    },
    offerEdit : (state, action) => {
      state.offerEdit = action.payload;
    },
    offerEditMsg : (state, action) => {
      state.offerEditMsg = action.payload;
    },
    statusOffer : (state, action) => {
      state.offer = action.payload;
    },
    offerDelete : (state) => {
      state.offerdeleteSuccess = true;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.offerdeleteSuccess = false;
      state.errorApiData = "";
      state.viewoffer = "";
    },
  },
})

// Action creators are generated for each case reducer function
export const { offerEditMsg,offerEdit,offerDetail,statusOffer,createOffer, offerDelete, apiFailed,allOffers,resetState } = offerSlice.actions

export default offerSlice.reducer


export const getOffers = (page) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/offer/getOffers?page=${page}`);
      dispatch(allOffers(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const addOffer = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/offer/addOffer`, values)
      dispatch(createOffer(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const updateOffer = (offer_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/offer/updateOffer`,offer_id)
      dispatch(offerEditMsg("Offer updated successfully"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailOffer = (offer_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/offer/viewOffer/`,{offerId:offer_id});
      dispatch(offerDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteOffer = (offer_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/offer/deleteOffer`,{offerId : offer_id})
      dispatch(offerDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const changeOfferStatus = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/offer/status`,statusData);
      dispatch(statusOffer());
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(offerEditMsg(""));
}