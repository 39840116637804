import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewuser:{},
  kycrequests : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:""
}

export const userKycRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = {};
    },
    logoutFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    adminProfile: (state, action) => {
      state.profile = action.payload;
    },
    allKycRequests : (state, action) => {
      state.kycrequests = action.payload;
    },
    kycRequestStatus : (state, action) => {
      state.statusMsg = action.payload;
    },
    kycDetail : (state, action) => {
      state.viewuser = action.payload;
    },
    resetState : (state) => {
      state.statusMsg = "";
    },
  },
})

// Action creators are generated for each case reducer function
export const {kycRequestStatus,kycDetail, loginSuccess, userDelete,logoutSuccess, adminProfile, apiFailed,allKycRequests,resetState } = userKycRequestSlice.actions

export default userKycRequestSlice.reducer

export const getKycRequests = (page) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/kyc/kycrequestlist?page=${page}`);
      dispatch(allKycRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/kyc/viewRequest/${user_id}`);
      dispatch(kycDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const kycStatusChange = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/kyc/status`,{id:statusData});
      console.log("response",res.data.message)
      dispatch(kycRequestStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const sendEmail = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/kyc/sendmail`,{data:statusData});
      //console.log("response",res.data.message)
      dispatch(kycRequestStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(kycRequestStatus(""));
}